const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
    var board = JXG.JSXGraph.initBoard('jxgbox5a', {boundingbox: [-2, 13, 14, -3], keepaspectratio: true, axis:false, grid: false, ticks:false, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
    //
    var line = board.create('line', [[0,0], [100, 0]], {visible: false, straightFirst: false, straightLast: false});
    //
    var point = board.create('glider', [8, 0, line], {name: 'Drag me!', size:function(){return 45*board.canvasHeight/800}, face:'square'});
    //
    var ktext= board.create('text',[function(){return point.X()/2.0}, 1.5, 'k'],{cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*board.canvasWidth/500.)}, fixed:true});
    //
    var mtext= board.create('text',[function(){return point.X()-0.25}, 0, 'm'],{cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*board.canvasWidth/500.)}, fixed:true});
    //
    var isInDragMode = false;
    //
    var springHangup = board.create('point', [0, 0], {color: 'black', name: '', fixed: true, visible:false});
    //
    var i=0;
    var numberOfSpringRings = 100;
    var springRings = [];
    //
    //for(i=0; i<numberOfSpringRings; i++) {
    //    springRings[i] = board.create('point', [function(i) {return function() { return springHangup.X()+(i+1)*Math.abs((springHangup.X() - point.X()+0.35)/(numberOfSpringRings+1))};}(i), 0.5-i%2 ], {withLabel: false, color: 'black', size: 1});
    for(i=0; i<numberOfSpringRings; i++) {
        springRings[i] = board.create('point', [function(i){return function(){return (point.X()/numberOfSpringRings)*i};}(i), function(i) {return function() { return 0.5*Math.sin(5000*i)};}(i) ], {withLabel: false, visible:false, color: 'black', size: 1});
          if(i>0)
            board.create('segment', [springRings[i-1], springRings[i]], {color: 'black', strokeWidth: 2});
          };
    //
  //const c = 0.001;
   var c = board.create('slider',[[2.,7.],[9.,7.],[0.0, 0.01, 0.05]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'c',size:function(){return 8*board.canvasHeight/800},face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(14*board.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
    //
    var m = board.create('slider',[[2.,5.],[9.,5.],[0.1, 0.25, 1]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'m(kg)',size:function(){return 8*board.canvasHeight/800},face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(14*board.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});    //
    var k = board.create('slider',[[2.,3.],[9.,3.],[0.1, 0.25, 1]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'k(N/m)',size:function(){return 8*board.canvasHeight/800},face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(14*board.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});    //
    var vbase=board.create('polygon',[[0,-4.75],[25,-4.75],[25,-0.95],[0, -0.95]], {fillColor:'lightgrey',fillOpacity:1.0, withLines:false, vertices:{visible:false}});
    //
    var hbase=board.create('polygon',[[-5,-4.75],[0,-4.75],[0,10],[-5, 10]], {fillColor:'lightgrey', fillOpacity:1.0, withLines:false, vertices:{visible:false}});
    board.create('segment', [springHangup, springRings[0]], {color: 'black', strokeWidth: 1});
    //
    board.create('segment', [springRings[numberOfSpringRings-1], point], {strokeColor: 'black', strokeWidth: 1});
    //
    var map= board.create('text',[6, 12, '<b>Spring-Mass System</b>'],{anchorX:'middle', cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(48*board.canvasWidth/800.)}, fixed:true});
    //
    function startAnimation(startY) {
      point.moveAlong(function() {
    var f = function(t, x) {
            return [x[1], (-c.Value()/m.Value())*x[1] - (k.Value()/m.Value()) * (x[0]-8)];};
    var area = [0, 200];
    var numberOfEvaluations = (area[1] - area[0]) * 100;
    var data = JXG.Math.Numerics.rungeKutta('heun', [startY, 0], area, numberOfEvaluations, f);
    var duration = 20 * 1e3;
    return function(t) {
        if (t >= duration)
            return NaN;
        return [data[Math.floor(t / duration * numberOfEvaluations)][0], startY];}
        }());}
//
    function hook() {
      if(!isInDragMode) {
    if(board.mode === board.BOARD_MODE_DRAG) {
        board.stopAllAnimation();
        isInDragMode = true;
    }
    }
    if(isInDragMode) {
    if(board.mode !== board.BOARD_MODE_DRAG) {
        isInDragMode = false;
        startAnimation(point.X());
    }
    }
    }
    board.addHook(hook);
    startAnimation(10);
},
}
export default Boxes;
